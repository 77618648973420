
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React from "react";
import * as s from "./About.module.css";

const About = ({ block }) => {
  const image = {
    data: block?.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.image?.altText || ``,
  };
  return (
    <>
      <section className={`${s.block}`}>
        <div className="container">
          <div className={`row align-items-center`}>
            <div className='col-lg-6'>
              {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
              {!!block.subtitle && <div className={`${s.subtitle}`}>{parse(block.subtitle)}</div>}
              {!!block.content && <div className={`${s.content}`}>{parse(block.content)}</div>}
              {!!block?.link?.url && <div className='mt-3'>
                <Link to={block?.link?.url} target={block?.link?.target} className={s.ctaBtn}>{block?.link?.title}</Link>
              </div>}
            </div>
            {(!!image?.data) && <div className='col-lg-6'>
              <div className={s.imageWrapper}>
                <GatsbyImage
                  loading="eager"
                  placeholder="dominantColor"
                  image={image.data}
                  alt={image.alt}
                  className={`mw-100`} />
              </div>
            </div>}
          </div>
        </div>
      </section>
    </>
  );
};


export default About;
