// extracted by mini-css-extract-plugin
export var bg = "Hero-module--bg--36d08";
export var block = "Hero-module--block--0851c";
export var date = "Hero-module--date--648d6";
export var dateTime = "Hero-module--date-time--fd627";
export var dateTimeWrapper = "Hero-module--dateTimeWrapper--c2d13";
export var form = "Hero-module--form--34e8a";
export var formTitle = "Hero-module--formTitle--99274";
export var location = "Hero-module--location--4f20a";
export var recodringVideo = "Hero-module--recodringVideo--5038a";
export var recording = "Hero-module--recording--702cb";
export var recordingForm = "Hero-module--recordingForm--0fc37";
export var recordingFormTitle = "Hero-module--recordingFormTitle--70f7b";
export var recordingFormWrapper = "Hero-module--recordingFormWrapper--d20e3";
export var recordingPreviewWrapper = "Hero-module--recordingPreviewWrapper--93dc2";
export var row = "Hero-module--row--dcfa0";
export var runningText = "Hero-module--running-text--e4645";
export var submitButton = "Hero-module--submitButton--69a96";
export var subtitle = "Hero-module--subtitle--6dfec";
export var thankYouText = "Hero-module--thankYouText--b46ab";
export var title = "Hero-module--title--e236f";
export var topBlockButton = "Hero-module--top-block-button--478e4";
export var youtubeVideo = "Hero-module--youtubeVideo--4e12f";
export var youtubeVideoSection = "Hero-module--youtubeVideoSection--70956";