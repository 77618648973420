// extracted by mini-css-extract-plugin
export var accordionItem = "FAQ-module--accordion-item--44239";
export var accordionWrapper = "FAQ-module--accordion-wrapper--25aed";
export var answer = "FAQ-module--answer--ce41f";
export var ctaBtn = "FAQ-module--ctaBtn--8f6d9";
export var faq = "FAQ-module--faq--4fec8";
export var marker = "FAQ-module--marker--dc3b2";
export var question = "FAQ-module--question--86db5";
export var sectionImage = "FAQ-module--section-image--4baca";
export var sectionTitle = "FAQ-module--section-title--e96b1";
export var togglerIcon = "FAQ-module--toggler-icon--f1709";
export var wrapper = "FAQ-module--wrapper--63403";