import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import * as s from "./Speakers.module.css";

const Speakers = ({ block }) => {
  const bp = useBreakpoint();


  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    // centerMode: true,
    centerPadding: '8px',
  };

  return (
    <>
      {(!!block.show && block.speakers?.length > 0) &&
        <section className={`${s.block}`}>
          <div className="container">
            <div className={`row align-items-center`}>
              <div className="col-12">
                {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
              </div>
              <>
                {!!bp.md ?
                  <div className="container">
                    <div className="row justify-content-center">
                      {block.speakers.map((item, i) => {
                        return <div className="col-sm-12 col-md-6 col-xl-4 py-3" key={`webinar-item-${i}`}>
                          <Item speaker={item} />
                        </div>
                      })}
                    </div>
                  </div>
                  :
                  <div className="container-fluid px-0">
                    <Slider {...sliderSettings}>
                      {block.speakers.map((item, i) => {
                        return <div key={i} className="px-3"><Item speaker={item} /></div>
                      })}
                    </Slider>
                  </div>
                }
              </>
              {!!block?.link?.url && <div className='col-12 d-flex justify-content-center mt-3'>
                <Link className={s.ctaBtn} to={block?.link?.url} target={block?.link?.target}>{block?.link?.title}</Link>
              </div>}
            </div>
          </div>
        </section>
      }
    </>
  );
};

const Item = ({ speaker }) => {
  const image = {
    data: speaker?.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: speaker?.image?.altText || ``,
  };

  const companyLogo = {
    src: speaker?.companyLogo?.localFile?.publicURL,
    data: speaker?.companyLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: speaker?.companyLogo?.altText || ``,
  };

  return <div className={s.speaker}>
    {(image.data) &&
      <div className={s.imageWrapper}>
        <SpeakerImageBgSvg />
        <GatsbyImage
          loading="eager"
          placeholder="dominantColor"
          image={image.data}
          alt={image.alt}
          className={`mw-100`} />
      </div>
    }
    <div className='position-relative'>
      {!!speaker?.name && <div className={s.name}>{parse(speaker?.name)}</div>}
      {!!speaker?.position && <div className={s.position}>{parse(speaker?.position)}</div>}
    </div>
    {!!speaker?.linkedin &&
      <div className='position-relative'>
        <Link target='_blank' to={speaker?.linkedin}>
          <LinkedInSvg />
        </Link>
      </div>
    }
    {!!speaker?.description && <div className={s.description}>{parse(speaker?.description)}</div>}
    {!!companyLogo?.src && <div className='position-relative'>
      <img src={companyLogo?.src} alt={companyLogo.alt} className={`${s.companyLogo}`} />
    </div>}
  </div>
}


const LinkedInSvg = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
    <g clipPath="url(#clip0_11455_611)">
      <path d="M44.4469 0.754883H3.54375C1.58437 0.754883 0 2.30176 0 4.21426V45.2861C0 47.1986 1.58437 48.7549 3.54375 48.7549H44.4469C46.4062 48.7549 48 47.1986 48 45.2955V4.21426C48 2.30176 46.4062 0.754883 44.4469 0.754883ZM14.2406 41.658H7.11563V18.7455H14.2406V41.658ZM10.6781 15.6236C8.39062 15.6236 6.54375 13.7768 6.54375 11.4986C6.54375 9.22051 8.39062 7.37363 10.6781 7.37363C12.9563 7.37363 14.8031 9.22051 14.8031 11.4986C14.8031 13.7674 12.9563 15.6236 10.6781 15.6236ZM40.9031 41.658H33.7875V30.5205C33.7875 27.8674 33.7406 24.4455 30.0844 24.4455C26.3812 24.4455 25.8187 27.3424 25.8187 30.333V41.658H18.7125V18.7455H25.5375V21.8768H25.6312C26.5781 20.0768 28.9031 18.1736 32.3625 18.1736C39.5719 18.1736 40.9031 22.9174 40.9031 29.0861V41.658Z" fill="#063B6D" />
    </g>
    <defs>
      <clipPath id="clip0_11455_611">
        <rect width="48" height="48" fill="white" transform="translate(0 0.754883)" />
      </clipPath>
    </defs>
  </svg>
}

const SpeakerImageBgSvg = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 706 696" fill="none">
    <g filter="url(#filter0_f_11455_568)">
      <path d="M350.168 233.729C439.743 234.544 505.104 154.188 505.104 249.676C505.104 345.164 270.3 496.522 180.725 495.707C91.1506 494.892 180.725 342.212 180.725 246.724C180.725 151.236 260.593 232.914 350.168 233.729Z" fill="url(#paint0_linear_11455_568)" fillOpacity="0.85" />
    </g>
    <defs>
      <filter id="filter0_f_11455_568" x="-59.0857" y="0.423584" width="764.19" height="695.287" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_11455_568" />
      </filter>
      <linearGradient id="paint0_linear_11455_568" x1="140.914" y1="495.345" x2="183.06" y2="162.35" gradientUnits="userSpaceOnUse">
        <stop offset="0.354167" stopColor="#1BFED1" />
        <stop offset="0.515625" stopColor="#3A5CD5" />
        <stop offset="0.875" stopColor="white" stopOpacity="0.64" />
        <stop offset="1" stopColor="#030F1C" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
}

export default Speakers;
