import { graphql } from "gatsby"
import React from "react"

import schemaBuilder from "./../utils/schemaBuilder"

import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import About from "../components/webinarsRedesigned/About/About"
import FAQ from "../components/webinarsRedesigned/FAQ/FAQ"
import Hero from "../components/webinarsRedesigned/Hero/Hero"
import LearnBLock from "../components/webinarsRedesigned/LearnBLock/LearnBLock"
import PastWebinars from "../components/webinarsRedesigned/PastWebinars/PastWebinars"
import Speakers from "../components/webinarsRedesigned/Speakers/Speakers"
import RelatedPostsBlock from './../components/RelatedPostsBlock/RelatedPostsBlock'
import Seo from "./../components/seo"

const WebinarRedesignedTemplate = ({ data: { post, pastWebinars, site }, location }) => {


  console.log(pastWebinars);

  const schema = schemaBuilder({ webinar: post }, 'webinar', site);

  return (
    <div>
      <Header />
      <main>
        <Seo
          title={post.title}
          description={post.excerpt}
          pageSeo={post}
          location={location}
          schema={schema}
        />
        <Hero block={post.acf_webinar_redesigned?.heroRedesigned} />
        <About block={post?.acf_webinar_redesigned?.aboutBlockRedesigned} />
        <LearnBLock block={post?.acf_webinar_redesigned?.learnBlockRedesigned} />
        <Speakers block={post?.acf_webinar_redesigned?.speakersBlockRedesigned} />
        {!!post?.acf_webinar_redesigned?.pastWebinatsRedesigned?.show &&
          <PastWebinars block={{
            ...post?.acf_webinar_redesigned?.pastWebinatsRedesigned,
            webinars: pastWebinars?.webinars
          }} />
        }
        {!!post.acf_webinar_redesigned?.faqBlockRedesigned?.items?.length &&
          <FAQ block={{ items: post.acf_webinar_redesigned?.faqBlockRedesigned?.items, title: post.acf_webinar_redesigned?.faqBlockRedesigned?.title }} />
        }
        {post.acf_webinar_redesigned?.relatedPostsRedesigned?.length > 0 &&
          <RelatedPostsBlock block={{ posts: post.acf_webinar_redesigned?.relatedPostsRedesigned, title: <>Related Blog Posts</> }} />
        }
      </main>
      <Footer hideSubscribeBlock={true} />
    </div>
  )
}

export default WebinarRedesignedTemplate

export const pageQuery = graphql`
  query RedesignedWebinarById(
    $id: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    pastWebinars: allWpWebinar(
      filter: {
        id: {ne: $id},
        acf_webinar_redesigned: {generalRedesigned: {visibilityInRequests: {ne: "hidden"}}},
        acf_webinar: {general: {visibilityInRequests: {ne: "hidden"}}}
      }
      limit: 3
      sort: {fields: date, order: DESC}
    ) {
      webinars: edges {
       webinar: node {
          id
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    }
    post: wpWebinar(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      acf_webinar_redesigned {
        generalRedesigned {
          start
          end
          status
          youtubeUrl
          zoomUrl
        }
        heroRedesigned {
          title
          titleColor
          subtitle
          webinarDate
          time
          location
          form {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            youtubeVideoId
            title
            revenuesOptions {
              option
            }
            submitButtonText
            showRecordingVideoAfterSubmit
            zapierWebhookUrl
            thankYouText
            webinarRecordingYoutubeVideoId
            recordingPlaceholderImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
        aboutBlockRedesigned {
          title
          subtitle
          content
          link {
            target
            title
            url
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        learnBlockRedesigned {
          show
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          title
          items {
            title
            description
          }
        }
        speakersBlockRedesigned {
          show
          title
          speakers {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            name
            position
            linkedin
            description
            companyLogo {
              altText
              localFile {
                publicURL
              }
            }
          }
          link {
            target
            title
            url
          }
        }
        pastWebinatsRedesigned {
          show
          title
          subtitle
          link {
            target
            title
            url
          }
        }
        faqBlockRedesigned {
          title
          items {
            question
            answer
          }
        }
        relatedPostsRedesigned {
          post {
            ... on WpPost {
              excerpt
              seo {
                metaDesc
              }
              acf_post {
                heading {
                  cardBackground
                }
              }
              uri
              date(formatString: "MMM DD, YYYY")
              title
              excerpt
              categories {
                nodes {
                  slug
                  name
                }
              }
              readingTime {
                text
              }
              tags {
                nodes {
                  name
                }
              }
              author {
                node {
                  avatar {
                    size
                    url
                  }
                  users {
                    avatar {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                  name
                  slug
                  uri
                }
              }
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
